import React, { useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

const recaptchaRef = React.createRef()
const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, "Please provide a name with a minimum of 4 characters.")
    .required("Name field is required."),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email field is required."),
  subject: Yup.string()
    .min(8, "Please provide a subject with a minimum of 8 characters.")
    .required("Subject field is required."),
  message: Yup.string().required("Please write something for us."),
  recaptcha: Yup.string()
    .nullable()
    .required("Please verify that you are a Human."),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
const ContactForm = () => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [firstErrorField, setFirstErrorField] = useState(null)

  useEffect(() => {
    if (error && firstErrorField) {
      firstErrorField.focus()
    }
  }, [error, firstErrorField])

  return (
    <Formik
      validationSchema={ContactSchema}
      initialValues={{
        name: "",
        email: "",
        subject: "",
        message: "",
        recaptcha: "",
      }}
      onSubmit={(values, { resetForm }) => {
        setSubmitting(true)

        values["g-recaptcha-response"] = values.recaptcha

        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact",
            ...values,
          }),
        })
          .then(() => {
            setSubmitting(false)
            setSuccess(true)
            resetForm()
            recaptchaRef.current.reset()
          })
          .catch(error => {
            setSubmitting(false)
            setError(true)
            console.log(error)
          })
      }}
    >
      {({ errors, touched, validateField, validateForm, setFieldValue }) => (
        <Form
          name="contact"
          method="post"
          data-netlify="true"
          netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
          className="php-email-form"
        >
          <p className="text-muted ml-auto">
            <span className="req">*</span> are required fields
          </p>
          <div className="form-row">
            <div className="col-md-6 form-group">
              <Field
                name="name"
                type="text"
                className="form-control"
                placeholder="Name*"
                aria-label="Name*"
                aria-describedby={(errors.name && touched.name) ? "error-name" : null}
                ref={(ref) => {
                  if (errors.name && touched.name && !firstErrorField) {
                    setFirstErrorField(ref);
                  }
                }}
              />
              {errors.name && touched.name && (
                <div className="validate" id={"error-name"}>{errors.name}</div>
              )}
            </div>
            <div className="col-md-6 form-group">
              <Field
                name="email"
                type="text"
                className="form-control"
                placeholder="Email*"
                aria-label="Email*"
                aria-describedby={(errors.email && touched.email) ? "error-email" : null}
                ref={(ref) => {
                  if (errors.email && touched.email && !firstErrorField) {
                    setFirstErrorField(ref);
                  }
                }}
              />
              {errors.email && touched.email && (
                <div className="validate" id={"error-email"}>{errors.email}</div>
              )}
            </div>
          </div>
          <div className="form-group">
            <Field
              name="subject"
              type="text"
              className="form-control"
              placeholder="Subject*"
              aria-label="Subject*"
              aria-describedby={(errors.subject && touched.subject) ? "error-subject" : null}
              ref={(ref) => {
                if (errors.subject && touched.subject && !firstErrorField) {
                  setFirstErrorField(ref);
                }
              }}
            />
            {errors.subject && touched.subject && (
              <div className="validate" id={"error-subject"}>{errors.subject}</div>
            )}
          </div>
          <div className="form-group">
            <Field
              name="message"
              className="form-control"
              placeholder="Message*"
              aria-label="Message*"
              aria-describedby={(errors.message && touched.message) ? "error-message" : null}
              as="textarea"
              rows="5"
              ref={(ref) => {
                if (errors.message && touched.message && !firstErrorField) {
                  setFirstErrorField(ref);
                }
              }}
            />
            {errors.message && touched.message && (
              <div className="validate" id={"error-message"}>{errors.message}</div>
            )}
          </div>
          <div className="form-group">
            <ReCAPTCHA
              sitekey="6LditbsZAAAAAPHpKxIzUHB4Kknas9yfCS42y8SP"
              onChange={value => {
                setFieldValue("recaptcha", value)
              }}
              ref={recaptchaRef}
            />
            {errors.recaptcha && touched.recaptcha && (
              <div className="validate">{errors.recaptcha}</div>
            )}
          </div>
          <div className="mb-3">
            {submitting && <div className="loading" role="status">Loading</div>}
            {error && (
              <div className="error-message">
                Oops, something went wrong with your submission.
              </div>
            )}
            {success && (
              <div className="sent-message">
                Thank you for contacting us. We will get back to you shortly.
              </div>
            )}
          </div>
          <div className="d-flex align-items-baseline">
            <button type="submit">Send Message</button>
          </div>
        </Form>
      )}
    </Formik>
  )
}
export default ContactForm
